import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'

// Components
import SEO from 'components/shared/SEO'
import Layout from 'components/layout/Layout'
import ParseContent from '../components/shared/ParseContent'

const Col = styled.div`
  &:last-child {
    hr {
      display: none;
    }
  }
`

const Activiteit = styled.div`
  .description {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* number of lines to show */
    -webkit-box-orient: vertical;
  }

  .date {
    width: 58px;
    flex: 0 0 58px;
  }
`

const Agenda = ({
  data: {
    page: { path, title, yoast_meta: yoast },
    allWordpressWpActiviteiten: { nodes: activiteiten },
    allWordpressWpDoelgroep: { nodes: doelgroepenNodes },
  },
}) => {
  const doelgroepen = doelgroepenNodes.map((doelgroep) => {
    doelgroep.activiteiten = activiteiten
      .filter((activiteit) =>
        activiteit.doelgroep.includes(doelgroep.wordpress_id)
      )
      // check if date has expired
      .filter((activiteit) => new Date(activiteit.acf.date) >= new Date())
      // sort by ascending
      .sort((a, b) => {
        return new Date(a.acf.date) - new Date(b.acf.date)
      })

    return doelgroep
  })

  return (
    <Layout>
      <SEO yoast={yoast} path={path} />

      <section className="color-background-main mb-5">
        <div className="container text-white text-center py-5">
          <h1 className="font-size-60">{title}</h1>
        </div>
      </section>

      <section className="container mb-5">
        {doelgroepen.map((doelgroep) => {
          return (
            <Col key={doelgroep.id} className="row mb-4">
              <div className="col-lg-12">
                <h2 className="font-size-40 mb-4">{doelgroep.name}</h2>
              </div>
              {doelgroep.activiteiten.length > 0 ? (
                <React.Fragment>
                  {doelgroep.activiteiten.map((activiteit) => {
                    return (
                      <Activiteit
                        className="col-lg-6 font-family-secondary d-flex align-items-center mb-4"
                        key={activiteit.id}
                      >
                        <div className="d-flex flex-column mr-4 pr-2 date">
                          <span className="font-size-50 line-height-1 color-contrast text-center">
                            {activiteit.acf.day}
                          </span>
                          <span className="font-size-xm text-uppercase text-center color-contrast">
                            {activiteit.acf.month}
                          </span>
                        </div>

                        <div className="d-flex flex-column">
                          <strong className="font-size-xm">
                            {activiteit.title}
                          </strong>
                          <ParseContent
                            className="description children-mb-0"
                            content={activiteit.acf.description}
                          />
                        </div>
                      </Activiteit>
                    )
                  })}
                </React.Fragment>
              ) : (
                <div className="col-lg-12">
                  Geen activiteiten gevonden voor deze doelgroep
                </div>
              )}

              <hr className="mt-5" />
            </Col>
          )
        })}
      </section>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($wordpress_id: Int) {
    page: wordpressPage(wordpress_id: { eq: $wordpress_id }) {
      title
      path

      yoast_meta {
        name
        content
        property
      }
    }

    allWordpressWpActiviteiten {
      nodes {
        id
        title
        doelgroep
        acf {
          date
          day: date(formatString: "D", locale: "nl_NL")
          month: date(formatString: "MMM", locale: "nl_NL")
          description
        }
      }
    }

    allWordpressWpDoelgroep {
      nodes {
        id
        wordpress_id
        name
        slug
      }
    }
  }
`

export default Agenda
